import { CMSReferenceCache, CMSValidationResult, Parsing } from "@maxtull/botfuel-parsing-utils";
import CMSData from "./CMSData";
import { AlertsData } from "./Schemas/Alert";
import { ButtonsData } from "./Schemas/Button";
import { LocalisedStringData } from "./Schemas/LocalisedString";
import { SettingsData } from "./Schemas/Settings";
import { SettingsBackgroundData } from "./Schemas/SettingsBackground";
import { SettingsButtonsData } from "./Schemas/SettingsButtons";
import { SettingsColorsData } from "./Schemas/SettingsColors";
import { SettingsLinksData } from "./Schemas/SettingsLinks";
import { SettingsLogosData } from "./Schemas/SettingsLogos";
import { SettingsNewsData } from "./Schemas/SettingsNews";
import { SettingsWidgetsData } from "./Schemas/SettingsWidgets";

export class PortalCMSData extends CMSData {
    public Alerts = new AlertsData();
    public Buttons = new ButtonsData();
    public LocalisedStrings = new LocalisedStringData();
    public Settings = new SettingsData();
    public SettingsBackground = new SettingsBackgroundData();
    public SettingsButtons = new SettingsButtonsData();
    /** @deprecated No longer used - Tailwind colors are now used */
    public SettingsColors = new SettingsColorsData();
    public SettingsLinks = new SettingsLinksData();
    public SettingsLogos = new SettingsLogosData();
    public SettingsNews = new SettingsNewsData();
    public SettingsWidgets = new SettingsWidgetsData();

    public IsClient = true;
    public ContentVersion: string;

    constructor(jsonData: Record<string, object>, useParserV2: boolean) {
        super();
        this.ReadContentVersionFromMetadata(jsonData);
        console.log(`[CMSData] Parsing CMS content with version: ${this.ContentVersion}, useParserV2: ${useParserV2}`);

        if (useParserV2) {
            this.TryInitWithCodegen(jsonData);
        }
        else {
            this.InitWithReflection(jsonData);
        }
    }

    public override Validate() {
        const result = new CMSValidationResult();
        Parsing.ValidateDataDictionaries(this, result);
        return result;
    }

    protected ReadContentVersionFromMetadata(jsonData: Record<string, object>) {
        const metadata = jsonData?.["_meta"];
        if (metadata) {
            const metadataDict = metadata as Record<string, string | object>;
            if (metadataDict != null && metadataDict?.["_content_version"]) {
                this.ContentVersion = metadataDict["_content_version"].toString();
            }
        }
        if (!this.ContentVersion) {
            console.error("Failed to read content version from CMS data!")
        }
    }

    protected InitWithReflection(jsonData: Record<string, object>) {
        const referenceCache = new CMSReferenceCache();
        Parsing.ParseDictionariesParallel(this, jsonData, referenceCache);
        Parsing.CrossReferenceAll(this, referenceCache);
    }

    protected override InitWithCodeGen(jsonData: Record<string, object>) {
        throw new Error("Code gen is forbidden/incomplete.");
    }

    protected TryInitWithCodegen(jsonData: Record<string, object>) {
        const error = true;
        if (error) {
            console.error("[PortalCMSData.TryInitWithCodegen] Code gen is forbidden/incomplete but we are trying to init with code gen parser. Falling back to v1 parser");
            this.InitWithReflection(jsonData);
        }
    }
}

export class CMSDataParsedEvent {
    public CMSData: PortalCMSData;
    public CMSParsedURL: string;

    constructor(cmsData: PortalCMSData, cmsParsedURL: string) {
        this.CMSData = cmsData;
        this.CMSParsedURL = cmsParsedURL;
    }
}