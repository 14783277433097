import { CMSDataCollection, CMSField, CMSEnum, CMSGroup, CMSDataGroup, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

export enum ButtonBorderStyle {
    None = 'none',
    Solid = 'solid',
    Dashed = 'dashed',
    Dotted = 'dotted',
    Double = 'double',
    '3D' = 'outset',
}

export class ButtonBorder extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('width')
    public Width: number

    @CMSField('style')
    @CMSEnum(ButtonBorderStyle)
    public Style: ButtonBorderStyle
}

export class ButtonShadow extends CMSDataCollection {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('opacity')
    public Opacity: number

    @CMSField('width')
    public Width: number

    @CMSField('offset_x')
    public OffsetX: number

    @CMSField('offset_y')
    public OffsetY: number
}

export enum ButtonShape {
    Rounded = 'rounded',
    Circle = 'circle',
    Square = 'square',
}

export enum ButtonSize {
    Small = '100',
    Medium = '110',
    Large = '120',
}

@CMSGroup(CMSConstants.Groups.SettingsButtons, false)
export class SettingsButtons extends CMSDataGroup {
    @CMSField('shape')
    @CMSEnum(ButtonShape)
    public Shape: ButtonShape

    @CMSField('size')
    @CMSEnum(ButtonSize)
    public Size: ButtonSize

    @CMSField('border')
    public Border: ButtonBorder

    @CMSField('shadow')
    public Shadow: ButtonShadow
}

@CMSClass(SettingsButtons)
export class SettingsButtonsData extends Map<string, SettingsButtons> { }