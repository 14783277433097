import useWeather from "../../hooks/useWeather";

export default function Weather() {
    const weather = useWeather();

    if (!weather) {
        return null;
    }

    return (
        <div className="text-center flex-1 sm:flex-initial text-nowrap hover:bg-accent-hover transition-colors py-4 px-6 rounded-3xl bg-stpauls-secondary border-2 border-stpauls-secondary">
            <i className="bi bi-sun-fill me-2" />
            {Math.round(weather.main.temp)}°C
        </div>
    )
}