import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.Settings, false)
export class Settings extends CMSDataGroup {
    @CMSField('value')
    public Value: boolean
}

@CMSClass(Settings)
export class SettingsData extends Map<string, Settings> { }