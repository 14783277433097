import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.LocalisedStrings, false)
export class LocalisedString extends CMSDataGroup {
    @CMSField('text')
    public Text: string

    public override toString(): string {
        return this?.Text ?? "";
    }
}

@CMSClass(LocalisedString)
export class LocalisedStringData extends Map<string, LocalisedString> { }