import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsColors, false)
export class SettingsColors extends CMSDataGroup {
    /** @deprecated No longer used - Tailwind colors are now used */
    @CMSField('value')
    public Value: string
}

@CMSClass(SettingsColors)
export class SettingsColorsData extends Map<string, SettingsColors> { }