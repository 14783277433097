import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsNews, false)
export class SettingsNews extends CMSDataGroup {
    @CMSField('enabled')
    public Enabled: boolean

    @CMSField('count')
    public Count: number

    @CMSField('scroll')
    public Scroll: boolean

    @CMSField('show_date')
    public ShowDate: boolean
}

@CMSClass(SettingsNews)
export class SettingsNewsData extends Map<string, SettingsNews> { }