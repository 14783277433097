import LocalisedStrings from "../Definitions/LocalisedStrings";
import Time from "./widgets/Time";
import Weather from "./widgets/Weather";

export default function Header(props: { website?: string, logo?: string, icon?: string }) {
    return (
        <header className="sticky top-0 z-10 p-4 bg-white">
            <div className="max-w-screen-xl mx-auto flex flex-row items-center justify-between text-black">
                <div className="w-full sm:w-1/3 flex items-center justify-start">
                    <a href={props.website} target="_blank" rel="noreferrer">
                        <img src={props.logo} className="hidden lg:block h-16" alt="Logo" />
                        <img src={props.icon} className="block lg:hidden h-12 sm:h-16" alt="Logo" />
                    </a>
                </div>
                <div className="hidden lg:block text-center text-nowrap">
                    <h1 className="text-3xl font-black text-stpauls-primary">
                        {LocalisedStrings.GetString('title')}
                    </h1>
                </div>
                <div className="text-center w-full sm:w-1/3 flex flex-row gap-2 sm:gap-4 items-center justify-center sm:justify-end text-xs sm:text-sm font-bold text-black">
                    <Time />
                    <Weather />
                </div>
            </div>
        </header>
    )
}