import { useState } from "react";

export default function Time() {
    const [time, setTime] = useState(new Date());

    setInterval(() => {
        setTime(new Date());
    }, 1000);

    return (
        <div className="flex-1 sm:flex-initial text-nowrap hover:bg-accent-hover transition-colors py-4 px-6 rounded-3xl border-2 border-black">
            <i className="bi bi-moon-stars-fill me-2" />
            {time.toLocaleTimeString('en-AU', { hour: '2-digit', minute: '2-digit' }).toUpperCase()}
        </div>
    )
}