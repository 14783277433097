import useNews from "../hooks/useNews";

export default function News(props: { count: number, showDate: boolean }) {
    const news = useNews();

    if (!news.length) {
        return null;
    }

    return (
        <header className="sticky top-16 z-10 p-4 bg-stpauls-primary text-white">
            <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center justify-evenly gap-y-2">
                {news.slice(0, props.count).map((newsEvent, index) => (
                    <a key={index} className={`flex flex-col text-center hover:underline ${index === 0 ? 'lg:w-auto' : 'hidden lg:flex'}`} href={newsEvent.url} target="_blank" rel="noreferrer">
                        <h3 className="text-sm font-bold">
                            {newsEvent.title}
                        </h3>
                        {props.showDate && (
                            <p className="text-xs">
                                {newsEvent.date}
                            </p>
                        )}
                    </a>
                ))}
            </div>
        </header>
    )
}